.mobile-filter-container {
  display: none;
}
.tablet-filter-container {
  display: none;
}

.filter-menu-container {
  width: 100%;
  background-color: white;
  border: solid 1px var(--borderGrey);
  border-radius: 12px;
  box-sizing: border-box;
  padding: 16px;
}

.filter-header {
  font-weight: 600;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  color: var(--textBlack);
  margin-bottom: 16px;
}

.filter-header-arrow-icon {
  height: 20px;
  width: 20px;
  transition: 0.2s;
  cursor: pointer;
}

.tablet-icon-cancel-small {
  display: none;
}

.filter-category-header {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  color: var(--textBlack);
  margin: 8px 0 8px 0;
}

.filter-category-container {
  display: flex;
  flex-direction: column;
}

.filter-subcategories-container {
  display: flex;
  flex-direction: column;
  max-height: 1500px;
  transition: max-height 0.2s;
  overflow: auto;
}

.closed {
  max-height: 0px;
}

.filter-subcategory-container {
  position: relative;
}

.filter-count-tag {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: var(--passive-text);
}

.checkbox-container {
  margin: 5px 8px 3px 0;
}

.subcategory-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--textBlack);
}

.checkbox-container {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  border: solid 1px #dfdfe7;
  border-radius: 4px;
}

.checkbox {
  height: 24px;
  width: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: opacity 0.2s;
}

.desktop-clear-all-button {
  border: solid 1px var(--colorPrimary);
  border-radius: 4px;
  color: var(--colorPrimary);
  font-weight: 500;
  background-color: white;
  font-size: 14px;
  box-sizing: border-box;
  padding: 6px 12px;
}

.desktop-clear-all-button-bottom {
  border: solid 1px var(--colorPrimary);
  border-radius: 4px;
  color: var(--colorPrimary);
  font-weight: 500;
  background-color: white;
  font-size: 16px;
  box-sizing: border-box;
  padding: 10px 0;
  width: 100%;
  margin-top: 16px;
}

.category-divider {
  width: 100%;
  border: none;
  border-top: solid 1px var(--separatorGrey);
  margin: 16px 0;
}

button:focus {
  outline: none;
}

button:active {
  background-color: #fff2e6;
}

button:hover {
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .tablet-filter-container {
    display: initial;
  }

  .tablet-filter-controller {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 17px 24px 17px 16px;

    background-color: white;
    border-radius: 12px;
  }

  .tablet-filter-controller-right {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tablet-filter-controller-text {
    font-size: 16px;
    color: var(--textBlack);
    font-weight: 500;
  }

  .filter-container-arrow-icon {
    width: 20px;
    height: 20px;
  }

  .tablet-filter-menu-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 41%;
    height: 100%;
    z-index: 1002;
    transition: 0.3s;
    background-color: white;
    border: solid 1px var(--borderGrey);
    box-sizing: border-box;
    padding: 76px 16px 92px 16px; /* Bottom controllers are 76 px in height and we need 16 px padding originally */
    overflow: scroll;
  }

  .filter-menu-container {
    height: 100%;
    margin: 0;
  }

  .filter-menu-bottom-controllers {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 41%;
    box-sizing: border-box;
    padding: 16px;

    background-color: white;
    border: solid 1px var(--borderGrey);
    box-shadow: 0 -2px 8px 0 rgba(111, 112, 115, 0.24);

    display: flex;
    justify-content: space-between;
  }

  .tablet-filter-menu-clear-button,
  .tablet-filter-menu-apply-button {
    background-color: var(--colorPrimary);
    border-radius: 4px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    width: 47%;
    height: 44px;
    border: none;
  }

  .tablet-filter-menu-backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1001;
    background-color: rgb(0, 0, 0, 0.4);
  }

  .tablet-icon-cancel-small {
    display: initial;
    width: 30px;
    height: 30px;
    margin-right: -5px;
  }

  .desktop-clear-all-button {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .mobile-filter-container {
    display: initial;
  }
  .tablet-filter-container {
    display: none;
  }
  .tablet-filter-menu-container {
    width: 100%;
    z-index: 99999;
  }

  /* */
  .tablet-filter-controller {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 17px 24px 17px 16px;
    background-color: #273c75;
    border-radius: 35px !important;
  }

  .tablet-filter-controller-text {
    margin-left: 1.2vh;
    margin-right: 2vh;
    font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
  }

  .mobile-filter-container-icon {
    margin-left: 2vh;
    width: 20px;
    height: 20px;
  }
  /* */

  .filter-menu-bottom-controllers {
    width: 100%;
  }

  .tablet-filter-menu-backdrop {
    display: none;
  }

  .tablet-filter-menu-clear-button {
    border: solid 1px var(--colorPrimary);
    border-radius: 4px;
    color: var(--colorPrimary);
    font-weight: 500;
    background-color: white;
    font-size: 16px;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .tablet-filter-controller {
    min-height: 44px;
    height: 100%;
    padding: 4px 8px;
  }

  .tablet-filter-controller-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tablet-filter-controller-header {
    font-size: 14px;
    font-weight: normal;
  }

  .tablet-filter-controller-count {
    font-size: 14px;
  }

  .filter-count-tag {
    display: none;
  }
  @media screen and (max-height: 900px) {
    .tablet-filter-controller {
      min-height: 40px;
    }
    .tablet-filter-controller-text {
      margin-left: 1vh;
      margin-right: 2vh;
      font-size: 20px;
    }
    .mobile-filter-container-icon {
      margin-left: 2vh;
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (max-height: 750px) {
    .tablet-filter-controller {
      min-height: 38px;
    }
    .tablet-filter-controller-text {
      font-size: 18px;
    }
    .mobile-filter-container-icon {
      width: 18px;
      height: 18px;
    }
  }
  @media screen and (max-height: 700px) {
    .tablet-filter-controller {
      min-height: 37px;
    }
    .tablet-filter-controller-text {
      font-size: 17px;
    }
    .mobile-filter-container-icon {
      width: 17px;
      height: 17px;
    }
  }
  @media screen and (max-height: 650px) {
    .tablet-filter-controller {
      min-height: 35.5px;
    }
    .tablet-filter-controller-text {
      font-size: 15.5px;
    }
    .mobile-filter-container-icon {
      margin-left: 2.5vh;
      width: 15.5px;
      height: 15.5px;
    }
  }
  @media screen and (max-height: 600px) {
    .tablet-filter-controller {
      min-height: 34px;
    }
    .tablet-filter-controller-text {
      font-size: 14px;
    }
    .mobile-filter-container-icon {
      margin-left: 2.5vh;
      width: 14px;
      height: 14px;
    }
  }
  @media screen and (max-height: 560px) {
    .tablet-filter-controller {
      min-height: 32px;
    }
    .tablet-filter-controller-text {
      font-size: 13.5px;
    }
    .mobile-filter-container-icon {
      width: 13px;
      height: 13px;
    }
  }
  @media screen and (max-height: 500px) {
    .tablet-filter-controller {
      min-height: 30px;
    }
    .tablet-filter-controller-text {
      font-size: 12px;
    }
    .mobile-filter-container-icon {
      width: 12px;
      height: 12px;
    }
  }
  @media screen and (max-height: 450px) {
    .tablet-filter-controller {
      min-height: 27px;
    }
    .tablet-filter-controller-text {
      font-size: 11px;
    }
    .mobile-filter-container-icon {
      width: 11px;
      height: 11px;
    }
  }
}

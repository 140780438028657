/* PROMO CODE */
@keyframes swipe {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.car-search-promo-code-applied {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: #009750;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500px;
  padding-bottom: 6px;
  gap: 4px;
  animation: swipe 0.4s ease;
}

@media screen and (max-width: 576px) {
  .car-search-promo-code-applied {
    display: none;
  }
}

.car-search-results-container {
  display: flex;
  max-width: 1120px;
  margin: auto;
  justify-content: space-between;
}

.car-search-results-container > *:hover {
  cursor: default;
}

.left-column-container {
  display: inline-flex;
  flex-direction: column;
  margin-right: 24px;
  margin-top: 24px;
  max-width: 784px;
  min-width: 320px;
}

.right-column-container {
  width: 100%;
}

.mobile-info-card {
  display: none;
}

.tablet-see-on-map {
  width: 20%;
  flex-grow: 2;
}

.filter-tags-and-sort-menu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
}

.filter-tags-container {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
}

.selected-filter-tag {
  display: inline-block;
  height: fit-content;

  background-color: white;
  border: solid 1px var(--borderGrey);
  border-radius: 22px;
  box-sizing: border-box;
  padding: 7px 6px;
  margin-right: 8px;
  margin-bottom: 8px;
}

.filter-tag-text {
  position: relative;
  bottom: 3px;
  margin: 0 6px;
}

.filter-tag-icons {
  display: inline-block;
  position: relative;
  top: 2px;
  width: 20px;
  height: 20px;
}

.clear-all-tag {
  background-color: var(--borderGrey);
}

.filter-tag-icon-cancel-all {
  position: absolute;
  width: 20px;
  height: 20px;
}

.filter-tag-icon-click {
  position: absolute;
  width: 20px;
  height: 20px;
}

.filter-tag-icon-click:hover {
  opacity: 0;
}

.filter-tag-icon-cancel {
  position: absolute;
  width: 20px;
  height: 20px;
  opacity: 0;
}

.filter-tag-icon-cancel:hover {
  opacity: 1;
}

.desktop-sort-container {
  position: relative;
  right: 9px;
  width: 24%;
}

.sort-container {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.sort-container-controller {
  display: flex;
  justify-content: space-between;

  height: 100%;
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--borderGrey);
  box-sizing: border-box;
  padding: 9px 8px;
}

.sorting-options-popup {
  position: absolute;
  margin-top: 8px;
  z-index: 100;
  width: 100%;
  background-color: white;
  border-radius: 12px;
  border: solid 1px var(--borderGrey);
  box-shadow: 0 0 6px 2px rgba(111, 112, 115, 0.2);

  max-height: 200px;
  overflow: hidden;
  opacity: 1;
  transition: 0.2s;
}

.sorting-option {
  box-sizing: border-box;
  padding: 12px;
}

.sorting-option:hover {
  background-color: #eff1f8;
}

.sort-container-left {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.sort-container-header {
  font-size: 14px;
  color: var(--textGrey);
}

.sort-container-main {
  font-size: 16px;
  font-weight: 500;
  color: var(--textBlack);
}

.sort-container-right {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.sort-container-arrow-icon {
  transition: transform 0.2s;
  height: 20px;
  width: 20px;
}

.tablet-filter-sort-container {
  display: none;
}

.mobile-filter-sort-container {
  display: none;
}

@media screen and (max-width: 1200px) {
  .left-column-container {
    margin-right: 0;
  }

  .right-column-container {
    width: 100%;
    max-width: 784px;
    margin-top: 4px;
  }

  .info-card {
    margin-top: 0;
  }

  .car-search-results-container {
    flex-direction: column;
    align-items: center;
  }

  .left-column-container {
    max-width: 784px;
    width: 100%;
  }

  .desktop-sort-container {
    max-height: 0;
    display: none;
  }

  .tablet-filter-sort-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
  }

  .tablet-filter-container {
    width: 48.8%;
  }

  .sort-container {
    width: 48.8%;
  }

  .sort-container-controller {
    padding-right: 24px;
    padding-left: 16px;
    border-radius: 12px;
  }

  .filter-tags-container {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .left-column-container {
    margin: 0;
  }

  .mobile-info-card {
    position: fixed;
    top: 0;
    z-index: 1000;
    display: flex;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    color: white;
    background-color: #273c75;
    padding: 8px 16px;
    justify-content: space-between;
  }

  .info-card {
    display: none;
  }

  .tablet-filter-container {
    width: 50%;
  }

  .sort-container {
    width: 50%;
  }

  .tablet-filter-sort-container {
    display: none;
  }

  .mobile-filter-sort-container {
    display: flex;
    width: 100%;
  }

  .tablet-filter-controller {
    border-radius: 0;
  }

  .sort-container-controller {
    border-radius: 0;
    min-height: 44px;
    height: 100%;
    padding: 4px 8px;
  }

  .filter-tags-and-sort-menu {
    margin: 0;
  }

  .filter-tags-container {
    display: none;
  }

  .sort-container-main {
    font-size: 14px;
  }

  .mobile-scroll-container {
    /* It cancel scroll to top when filter applied */
    /* overflow-y: scroll; */ 
    scroll-snap-type: y mandatory;
    overscroll-behavior: auto contain;
    padding-top: 50px;
  } 

  .mobile-scroll-container > :first-child {
    margin-top: 5vh;
  }

  .mobile-card {
    scroll-snap-align: center;
    scroll-snap-stop: always;
    scroll-margin-bottom: 90px;
  }

  .mobile-sticky-elements-container {
    display: flex;
    flex-flow: row nowrap;
    position: -webkit-sticky;
    position: sticky;
    bottom: 17%;
    margin-left: auto;
    margin-right: auto;
    background-color: #273c75;
    width: max-content;
    border-radius: 30px;
  }

  @media screen and (max-height: 900px) {
    .mobile-card {
      scroll-margin-bottom: 80px;
    }

    .mobile-sticky-elements-container {
      bottom: 17%;
      border-radius: 50px;
    }
  }

  @media screen and (max-height: 800px) {
    .mobile-card {
      scroll-margin-bottom: 70px;
    }

    .mobile-sticky-elements-container {
      bottom: 16.5%;
      border-radius: 50px;
    }
  }

  @media screen and (max-height: 700px) {
    .mobile-card {
      scroll-margin-bottom: 55px;
    }

    .mobile-sticky-elements-container {
      bottom: 16.4%;
    }
  }

  @media screen and (max-height: 600px) {
    .mobile-card {
      scroll-margin-bottom: 43px;
    }

    .mobile-sticky-elements-container {
      bottom: 15.5%;
    }
  }

  @media screen and (max-height: 550px) {
    .mobile-card {
      scroll-margin-bottom: 40px;
    }
  }

  @media screen and (max-height: 500px) {
    .mobile-card {
      scroll-margin-bottom: 0px;
    }

    .mobile-sticky-elements-container {
      bottom: 9%;
    }
  }
}

@media screen and (max-width: 360px) {
  .sort-container-main {
    font-size: 3.64vw;
  }
}

.see-on-map {
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;

  box-sizing: border-box;
  padding: 15px;
  height: 80px;
  max-width: 784px;
  min-width: 320px;
  margin-bottom: 16px;
}

.see-on-map-icon {
  width: 24px;
}

.see-on-map-text {
  font-weight: 500;
  color: #525266;
}

@media screen and (max-width: 1200px) {
  .see-on-map {
    min-width: initial;
    margin-left: 16px;
  }
}

@media screen and (max-width: 576px) {
  .see-on-map {
    background-size: 0 0;
    background-color: white;
    border: solid 1px #dfdfe7;
    width: 100%;
    margin: 0;
    flex-direction: row;
    height: fit-content;
    justify-content: center;
  }

  .see-on-map-icon {
    width: 20px;
    margin-right: 4px;
  }
}
